/* src/app/app.component.scss */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 0;
  min-height: 100%;
}
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
/*# sourceMappingURL=data:application/json;base64,ewogICJ2ZXJzaW9uIjogMywKICAic291cmNlcyI6IFsic3JjL2FwcC9hcHAuY29tcG9uZW50LnNjc3MiXSwKICAic291cmNlc0NvbnRlbnQiOiBbIi8qIFdyYXAgaGVhZGVycywgZm9vdGVycyBhbmQgY29udGVudCBpbiB0aGlzIG9uZSAqL1xuLnNwYi1ob2xkZXIge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBoZWlnaHQ6IDA7IC8vIFNvIHRoYXQgSUUgcmVhbGl6ZXMgd2UgYWN0dWFsbHkgaGF2ZSBhIGhlaWdodFxuICBtaW4taGVpZ2h0OiAxMDAlO1xufVxuXG5AbWVkaWEgcHJpbnRcbntcbiAgLm5vLXByaW50LCAubm8tcHJpbnQgKlxuICB7XG4gICAgZGlzcGxheTogbm9uZSAhaW1wb3J0YW50O1xuICB9XG59XG4iXSwKICAibWFwcGluZ3MiOiAiO0FBQ0EsQ0FBQTtBQUNFLFdBQUE7QUFDQSxrQkFBQTtBQUNBLFVBQUE7QUFDQSxjQUFBOztBQUdGLE9BQUE7QUFFRSxHQUFBO0VBQUEsQ0FBQSxTQUFBO0FBRUUsYUFBQTs7OyIsCiAgIm5hbWVzIjogW10KfQo= */
