import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {BlancolanComponent} from './loan/blancolan/blancolan.component'
import {deadGuard} from './application/guard'

export const routes: Routes = [
  {
    path: 'ansok',
    loadChildren: () => import('./loan/loan.module').then(m => m.LoanModule)
  },
  {
    path: 'admin',
    component: BlancolanComponent,
    canActivate: [deadGuard],
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'ansok',
    pathMatch: 'full'
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true,
    scrollPositionRestoration: 'disabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
